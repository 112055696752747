<template style="background: #E5E5E5">
  <v-container>
    <v-row justify="center">
      <v-dialog :value="dialog.show" persistent max-width="1400">
        <v-card class="pa-5">
          <v-card-title class="headline pl-3 ml-3">
            <v-row>
              <v-col cols="12" align="end">
                <v-icon large color="#4D596A" @click="onClickCancelButton()"
                  >fas fa-times</v-icon
                >
              </v-col>
              <v-col cols="12" style="color: #3b495b; font-weight: bold"
                >Titulares</v-col
              >
            </v-row>
          </v-card-title>

          <v-card-text>
            <v-data-table
              :headers="dialogHeaders"
              :items="physicalPersonsLoaded"
              :items-per-page="10"
              :calculate-widths="true"
              hide-default-footer
            >
              <template v-slot:[`item.selection`]="{ item }">
                <v-checkbox
                  v-model="selecteds"
                  :value="item.id"
                  color="textPrimary"
                >
                </v-checkbox>
              </template>

              <template v-slot:no-data>
                <span>Nenhum item foi encontrado.</span>
              </template>

              <template v-slot:[`item.name`]="{ item }">
                <span>{{ item.name ? formatter.formatToTitleCase(item.name) : null }}</span>
              </template>

              <template v-slot:[`item.cpf`]="{ item }">
                <span>{{ formatter.formatCpf(item.cpf) }}</span>
              </template>
            </v-data-table>
          </v-card-text>

          <v-card-actions>
            <v-row class="mt-6">
              <v-spacer></v-spacer>
              <v-btn
                class="mr-3 pa-7 btn-dialog"
                color="#3B495B"
                outlined
                @click="onClickCancelButton()"
              >
                Cancelar
              </v-btn>
              <v-btn
                class="mr-6 pa-7 btn-dialog"
                color="#3B495B"
                style="color: white"
                :disabled="!isDisabledOkButton"
                @click="onClickOkButton()"
              >
                OK
              </v-btn>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>
<script>
import Formatters from '@/shared/formatters/formatters';

export default {
  name: 'PhysicalPersonDialog',
  data: () => ({
    dialog: { show: false },
    dialogHeaders: [
      {
        text: ' ',
        value: 'selection',
        align: 'center',
        cols: '6',
      },
      {
        text: 'Nome',
        value: 'name',
        align: 'center',
        cols: '12',
      },
      {
        text: 'CPF',
        value: 'cpf',
        align: 'center',
        cols: '6',
      },
    ],
    selecteds: [],
    isDisabledOkButton: false,
    physicalPersonsLoaded: [],
  }),
  props: {
    physicalPersons: {
      type: Array,
      required: true,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    show(newValue) {
      this.dialog.show = newValue;
    },
    physicalPersons(newValue) {
      this.physicalPersonsLoaded = newValue;
    },
    selecteds(newValue) {
      if (newValue.length) {
        this.isDisabledOkButton = true;
      } else {
        this.isDisabledOkButton = false;
      }

      if (newValue.length > 1) {
        this.selecteds.shift();
      }
    },
  },
  methods: {
    onClickCancelButton() {
      this.isDisabledOkButton = false;
      this.selecteds = [];
      this.physicalPersonsLoaded = [];
      this.$emit('close');
    },
    onClickOkButton() {
      const idSelected = this.selecteds[0];
      this.$emit('confirm', this.physicalPersonsLoaded.find((p) => p.id === idSelected));
      this.selecteds = [];
      this.physicalPersonsLoaded = [];
      this.isDisabledOkButton = false;
    },
  },
  created() {
    this.formatter = new Formatters();
  },
};
</script>

<style scoped>
.btn-dialog {
  width: 200px;
  font-size: 16px;
}
</style>
