import { render, staticRenderFns } from "./PhysicalPersonDialog.vue?vue&type=template&id=789854eb&scoped=true&style=background%3A%20%23E5E5E5&"
import script from "./PhysicalPersonDialog.vue?vue&type=script&lang=js&"
export * from "./PhysicalPersonDialog.vue?vue&type=script&lang=js&"
import style0 from "./PhysicalPersonDialog.vue?vue&type=style&index=0&id=789854eb&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "789854eb",
  null
  
)

export default component.exports