/* eslint-disable */
import HttpService from '../HttpService';
export default class DependentService {
  constructor() {
    this._httpService = new HttpService('/contract/dependent');
  }

  async CheckDependentThirteenRules(data){
    return await this._httpService.post( `/check-dependent-thirteen-rules`, data);
  }
};
